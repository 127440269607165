@import "normalize";

* {
  box-sizing: border-box;
}

body {
  border-top: 7px solid #fcd0a1;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.3;
}

a {
  color: #fcd0a1;
  text-decoration: none;
}

h1, h3 {
  font-family: 'Roboto Slab', serif;
}

h1 {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 2.3rem;
}

h2 {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 1rem;
}

h3 {
  font-weight: normal;
  margin-bottom: 0;
  font-size: 1.2rem;
}

.highlight {
  font-family: 'Roboto Slab', serif;
}

p {
  margin-top: 0;
  font-size: 0.9rem;
}

img {
  max-width: 100%;
}

.vita-section {
  margin-top: 2rem;
}

.container {
  max-width: 1024px;
  margin: 10px auto 0 auto;
  display: flex;

  .col {
    padding: 20px;
    flex-grow: 1;
    width: 50%;

    //&:first-child {
    //  width: 35%;
    //}
  }
}

footer {
  text-align: center;

  p {
    width: 100%;
  }
}


// Step 7
// hide the input checkbox
// keep visable for screen reader
// then finally add transition to
// label and pseudo element
input {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  bottom: 0;

  &:checked {
    + label {
      background: #fcd0a1;

      &:after {
        right: 3px;
        background-color: #fff;
        box-shadow: -2px 0px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.dark-switch-wrapper {
  text-align: right;
  max-width: 1024px;
  margin: 0 auto;

  .dark-switch {
    display: inline-block;
    font-size: 0.8rem;
    text-align: center;

    .dark-switch-text {
      margin-top: 2px;
      text-transform: uppercase;
    }
  }
}

label {
  display: inline-block;
  position: relative;
  height: 30px;
  width: 60px;
  background-color: #ccc;
  transition: background-color 500ms ease;
  cursor: pointer;
  font-size: 0;
  color: transparent;
  border-radius: 22px;


  &:after {
    content: '';
    display: block;
    height: 22px;
    width: 22px;
    position: absolute;
    top: 4px;
    right: 34px;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 2px 0px 0px rgba(0, 0, 0, 0.15);
    transition: right 500ms ease,
    background-color 500ms ease,
    box-shadow 500ms ease;
  }
}

body.dark-mode {
  background: #131313;
  color: #e0e0e0;

  a {
    color: #e0e0e0;
  }
}

.mobile-header {
  display: none;
}

@media screen and (max-width: 768px) {
  .desktop-header {
    display: none;
  }
  .dark-switch {
    float: right;
    margin-top: 7px;
    margin-right: 6px;
  }

  .mobile-header {
    margin-top: 10px;
    display: block;
  }

  .dark-switch-wrapper {
    text-align: left;
    padding: 20px;
  }

  .container {
    flex-direction: column;

    .col {
      width: 100%;
      padding-top: 0;

      &:first-child {
        padding-bottom: 0;
      }

      &:last-child {
        padding-top: 0;
      }
    }
  }

}

.vita-section a {
  color: black;

  &:hover {
    color: #fcd0a1;
  }
}
